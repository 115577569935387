import NotificationsIcon from 'assets/img/icon-notifications.svg';
import VisibilityOffIcon from 'assets/img/icon-visibility-off.svg';
import VisibilityOnIcon from 'assets/img/icon-visibility-on.svg';
import { formHeaderCss } from 'assets/styles/config';
import {
  colorWhite,
  colorPrimary,
  colorGrayLight08,
  colorSecondaryText,
  colorSecondaryGray,
  colorSecondaryGrayDark,
  colorSecondaryGrayLight,
  colorSecondaryGrayLight015,
} from 'assets/styles/variables';
import { USERS_GROUPS } from 'core/auth/constants';
import { UNITS_TYPES, FORM_UNITS_TYPES } from 'core/constants';
import hash from 'hash-sum';
import { get, isEmpty, toNumber, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { getHasPermissions } from 'utils/auth';
import { stringCompareFunction } from 'utils/helpers/sorting';
import { string, number, object, array } from 'yup';

import { categoryReviewValidator, onSubmit, otherReviewValidator } from './utils';

moment.tz.setDefault('UTC');

const { CPS } = USERS_GROUPS;

export const employeesReviewsManagementModelGetter = ({
  data: reviews,
  employeesList: { map, list: employeesList },
  projectsMap,
  projectsList,
  notificationsLog,
  userGroup,
  controls,
}) => {
  const isCPS = getHasPermissions(userGroup, [CPS]);

  return {
    isSingleTab: true,
    additionalDataGetter: () => ({
      controls: [],
      onSubmit: (args) => onSubmit({ ...args, employeesMap: map }),
      styledWrapperCssRules: `
      display: grid;
      grid-template-columns: 37rem minmax(0, 1fr) 16.8rem 18.2rem;
      grid-auto-rows: 3.8rem;
      grid-column-gap: 1rem;

      .calendar__date-wrapper {
        flex-grow: unset;
      }
    `,
      filterSelectCssRules: `
      && {
        flex-grow: 1;
        width: auto;
        padding-top: 0.5rem;
        color: ${colorPrimary};

        .filter-select {
          width: auto;
          max-width: 100%;
          min-width: 13.5rem;
          height: auto;

          &__multi-value__label {
            font-size: 1.6rem;
            color: ${colorPrimary};
          }
          &__placeholder {
            padding: 0;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: ${colorPrimary};
          }
          &__control--is-focused {
            outline: none !important;
          }
          &__dropdown-indicator {
            padding-bottom: 0.3rem;
            path {
              fill: ${colorPrimary};
            }
          }
       }
    `,
      monthPickerCssRules: `
      && {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        border: none;
        & path {
          fill: ${colorPrimary};
        }
        && .calendar-date-picker {
          & path {
            fill: ${colorSecondaryGray};
          }
        }
      }
    `,
      controlsCssRules: `
      &&& {
        position:
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 0.8rem;
        grid-auto-rows: minmax(3.2rem, auto);
        flex-direction: column;
        padding-top: 0.8rem;
        padding-bottom: 1.6rem;
        font-size: 1.5rem;
      }
    `,
      controlsWrapperCssRules: `
      position: sticky;
      top: 0;
      z-index: 1;
    `,
      wrapperCssRules: `
      min-height: calc(100vh - 22rem);
      padding-bottom: 10rem;
    `,
      loaderWrapperCssRules: `
      position: fixed;
      top: 21rem;
      width: 100%;
      height: calc(100vh - 21rem);
    `,
      singleRowCssRules: `
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 4.2rem;

      .employees_reviews__group_control {
        margin-left: auto;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 400;
      }
    `,
      selectCssRules: `
      && {
        padding-top: 0.4rem;
        color: ${colorPrimary};

      .filter-select {
        width: auto;
        height: auto;
        &__multi-value__label {
          color: ${colorPrimary};
        }
        &__placeholder {
          padding-left: 0;
          color: ${colorPrimary};
        }
        &__control--is-focused {
          outline: none !important;
        }
        &__dropdown-indicator {
          path {
            fill: ${colorPrimary};
          }
        }
      }
    }`,
    }),
    dataTemplate: [
      ...reviews.filter((review) => {
        if (controls.onlyCategoriesWithReviews) {
          return !review.isOther;
        }

        if (controls.isRatingLessThanThree && review.isOther && review.records.length === 0) {
          return !review.isOther;
        }
        return review;
      }).map(({
        teamManagement,
        teamMap,
        isOther,
        records,
        projectMap,
        jiraProject,
        jiraProjectName,
        listOfDevstaffIdsThatHasNoReviews = [],
      }) => {
        const numberOfRequiredReviews =
          listOfDevstaffIdsThatHasNoReviews.length;
        const hasNeedToNotifyPl = listOfDevstaffIdsThatHasNoReviews.some(({ isPl }) => !isPl);
        const notificationDate = get(notificationsLog, jiraProject);
        return {
          type: UNITS_TYPES.FORM,
          formId: jiraProject,
          hasRequiredReviews: !!numberOfRequiredReviews,
          additionalFields: {
            teamMap,
            projectMap,
            projectsMap,
          },
          withoutFormControls: true,
          stylesTemplate: {
            errorBoxCssRules: 'background: #f2f2f2;',
            formWrapperStyles: `
            && {
              flex-grow: unset;
              padding: 0;
              overflow: visible;
            }
          `,
          },
          content: [
            {
              formData: [
                {
                  isFieldArray: true,
                  summaryConfig: {
                    label: jiraProjectName,
                    groupId: 'reviews',
                    isDefaultExpanded: true,
                    items: numberOfRequiredReviews ?
                      [
                        {
                          data: {
                            value: `${numberOfRequiredReviews} review${
                              numberOfRequiredReviews > 1 ? 's' : ''
                            } required`,
                          },
                        },
                        hasNeedToNotifyPl && {
                          type: 'dropdown',
                          iconSrc: NotificationsIcon,
                          onClick: ({
                            actionArguments,
                            actions: { notifyProjectLead },
                          }) => notifyProjectLead(actionArguments),
                          controls: [
                            {
                              type: 'text',
                              data: notificationDate ?
                                `Notification was sent ${moment(notificationDate)
                                  .tz('America/New_York')
                                  .format('L LT')}` :
                                'Notification wasn\'t sent',
                              cssRules: `
                        && {
                          padding: 0;
                        }
                      `,
                            },
                            {
                              type: 'action',
                              withBody: true,
                              data: `${
                                notificationDate ? 'Res' : 'S'
                              }end Notification`,
                              actionArguments: {
                                projectId: jiraProject,
                              },
                              cssRules: `
                        && {
                          width: 100%;
                          padding: 0.7rem 2.4rem;
                          font-size: 1.3rem;
                          line-height: 1.6rem;
                          text-align: center;
                          color: ${colorWhite};
                          background-color: ${colorPrimary};
                        }
                      `,
                            },
                          ],
                          cssRules: `
                      margin: 0 0 0 0.4rem;
                      &.dropdown--opened {
                        background-color: #fff;
                      }
                    `,
                          expandSectionCssRules: `
                      && {
                        width: 32rem;
                        right: 0!important;
                        left: unset!important;
                        background-color: #fff;
                      }
                    `,
                        },
                      ] :
                      [],
                    cssRules: `
                    min-height: 4.8rem;
                    font-size: 1.4rem;
                    line-height: 2.1rem;
                    padding: 0 1.6rem;
                    border-top: 1px solid ${colorSecondaryGrayLight};
                    background-color: ${colorSecondaryGrayLight015};
                    color: ${colorSecondaryGrayDark};
                    & .expansion-summary__item {
                      color: ${colorPrimary};
                      &:after {
                        display: none;
                      }
                    }
                    & .expansion-summary__label {
                      font-size: 1.6rem;
                      line-height: 2.4rem;
                    }
                  `,
                  },
                  name: `${jiraProject}`,
                  validateOnChange: false,
                  valueGetter: () => {
                    const filedArrayRootName = jiraProject;
                    const defaultReview = {
                      isAdminStaffReview: null,
                      isCustomerReview: null,
                      rating: null,
                      recordId: null,
                      review: '',
                      reviewby: '',
                      reviewbyId: null,
                    };
                    let hasRequiredReviews = false;
                    const storedData = {};
                    const employeesMap = {
                      ...map,
                      ...teamMap,
                    };

                    return {
                      title: 'Reviews',
                      onPushLine: ({ addLine }) => {
                        addLine({
                          isPl: false,
                          isReviewByPl: false,
                          isEmpty: true,
                          isBlocked: true,
                          isOutOfProject: true,
                          reviews: [
                            {
                              isAdminStaffReview: null,
                              isCustomerReview: null,
                              rating: null,
                              recordId: null,
                              review: '',
                              reviewby: '',
                              reviewbyId: null,
                            },
                          ],
                        });
                      },
                      defaultOrderRules: {
                        orderBy: 'Resource',
                        comparator: (left, right, isReversed) => {
                          const leftEmployeeName = get(left, 'devstaffName');
                          const rightEmployeeName = get(
                            right,
                            'devstaffName',
                          );
                          const leftIsPl = get(left, 'isPl');
                          const rightIsPl = get(right, 'isPl');
                          const leftIsEmpty = get(left, 'isEmpty');
                          const rightIsEmpty = get(right, 'isEmpty');
                          const leftLoggedHrs = get(left, 'loggedHrs');
                          const rightLoggedHrs = get(right, 'loggedHrs');
                          const isLeftAdditionalReviews =
                            !toNumber(leftLoggedHrs);
                          const isRightAdditionalReviews =
                            !toNumber(rightLoggedHrs);
                          if (leftIsEmpty || rightIsEmpty) {
                            return 0;
                          }
                          if (
                            isLeftAdditionalReviews ||
                            isRightAdditionalReviews
                          ) {
                            if (
                              isLeftAdditionalReviews &&
                              isRightAdditionalReviews
                            ) {
                              return stringCompareFunction(
                                leftEmployeeName,
                                rightEmployeeName,
                                isReversed,
                              );
                            }
                            return isRightAdditionalReviews ? -1 : 1;
                          }
                          if (
                            (leftIsPl || rightIsPl) &&
                            !(leftIsPl && rightIsPl)
                          ) {
                            return leftIsPl ? -1 : 1;
                          }
                          return stringCompareFunction(
                            leftEmployeeName,
                            rightEmployeeName,
                            isReversed,
                          );
                        },
                      },
                      onUpdateFieldValue: ({
                        index,
                        values,
                        fieldName,
                        setFieldValue,
                      }) => {
                        const currentLineData = get(values, index);
                        const { isBlocked, devstaffId } =
                          currentLineData;
                        const storedReviews = get(storedData, devstaffId, []);

                        if (!isBlocked) {
                          storedData[devstaffId] = currentLineData.reviews;
                        }

                        const defaultLineData = {
                          ...currentLineData,
                          isBlocked: !isBlocked,
                          reviews: isBlocked ? storedReviews : [],
                        };

                        setFieldValue(fieldName, defaultLineData);
                      },
                      value: records,
                      lineGetter: ({ index, lineValues }) => {
                        const {
                          isPl,
                          role,
                          loggedHrs,
                          devstaffId,
                          defaultRole,
                          devstaffName,
                          isOutOfProject,
                          isBlocked,
                          reviews: storedReviews,
                          jiraProjectName: storedJiraProjectName,
                        } = lineValues || {};

                        if (!index) {
                          hasRequiredReviews = false;
                        }
                        const isMoreThatOneReview = storedReviews.length > 1;
                        const isLastStoredReview = storedReviews.length === 1;
                        hasRequiredReviews =
                          hasRequiredReviews ||
                          (isBlocked && !isOutOfProject);

                        return {
                          lineId: hash({
                            index,
                            devstaffId,
                            devstaffName,
                            storedJiraProjectName,
                          }),
                          line: [
                            {
                              type:
                                isOutOfProject ?
                                  FORM_UNITS_TYPES.EMPTY :
                                  FORM_UNITS_TYPES.ACTION_BUTTON,
                              data: '',
                              actionName: 'updateFieldValue',
                              actionArguments: {
                                index,
                                fieldName: `${filedArrayRootName}[${index}]`,
                              },
                              imgSrc: !isBlocked ?
                                VisibilityOnIcon :
                                VisibilityOffIcon,
                              cssRules: `
                              && {
                                display: flex;
                                justify-content: center;
                                margin-top: 2.4rem;
                                max-height: 3rem;
                              }

                              && .action-button__icon {
                                margin-left: 0;
                              }
                            `,
                            },
                            {
                              type: FORM_UNITS_TYPES.VIEW_BOX,
                              isHidden: isOutOfProject,
                              fieldData: {
                                value: devstaffName,
                                path: isCPS ?
                                  '' :
                                  `/delivery/employee/${devstaffId}/work-book`,
                              },
                              cssRules: `
                              border-bottom: none;
                              padding-top: 1.8rem;
                              font-size: 1.2rem;
                              line-height: 2.1rem;
                              color: ${
  !isBlocked ?
    `${
      isPl ? colorPrimary : colorSecondaryText
    }` :
    colorSecondaryGrayLight
};
                              & .viewbox__viewbox-link {
                                font-size: 1.2rem;
                                line-height: 4.4rem;
                              }
                            `,
                            },
                            {
                              type: FORM_UNITS_TYPES.SELECT,
                              label: 'Resource',
                              isHidden: !isOutOfProject,
                              name: `${filedArrayRootName}[${index}].devstaffId`,
                              placeholder: 'Resource',
                              withLabel: false,
                              fieldData: {
                                selected: {},
                                items: employeesList,
                              },
                              cssRules: `
                              padding-bottom: 0.3rem;
                              font-size: 1.2rem;
                              && .select-item__value-container {
                                overflow-x: hidden;
                              }
                              & .select-item__menu {
                                width: calc(120% + 0.8rem + 2px);
                              }
                              & .select-item__menu-list {
                                max-height: 25rem;
                              }
                              & .select-item__group {
                                padding: 0;
                              }
                              & .select-item__group-heading {
                                position: sticky;
                                top: 0;
                                margin: 0;
                                padding: 0.8rem 0;
                                border-bottom: 1px solid rgba(85, 86, 90, 0.2);
                                font-size: 1.2rem;
                                line-height: 1.6rem;
                                font-weight: 500;
                                text-align: center;
                                letter-spacing: 1.5px;
                                text-transform: uppercase;
                                background-color: ${colorWhite};
                                z-index: 1;
                              }
                            `,
                              validationRules: {
                                isRequired: true,
                              },
                            },
                            {
                              type: FORM_UNITS_TYPES.VIEW_BOX,
                              isHidden: isOther,
                              fieldData: {
                                value: isPl ? 'PL' : '',
                              },
                              cssRules: `
                              border-bottom: none;
                              padding-top: 1.8rem;
                              font-size: 1.2rem;
                              line-height: 2.1rem;
                              color: ${
  !isBlocked ?
    colorPrimary :
    colorSecondaryGrayLight
};
                            `,
                            },
                            {
                              type: FORM_UNITS_TYPES.VIEW_BOX,
                              isHidden: !isOther || isOutOfProject,
                              fieldData: {
                                value: storedJiraProjectName,
                              },
                              cssRules: `
                              border-bottom: none;
                              padding-top: 1.8rem;
                              font-size: 1.2rem;
                              line-height: 2.1rem;
                              color: ${
  !isBlocked ?
    `${
      isPl ? colorPrimary : colorSecondaryText
    }` :
    colorSecondaryGrayLight
};
                            `,
                            },
                            {
                              type: FORM_UNITS_TYPES.SELECT,
                              label: 'Category',
                              isHidden: !isOther || !isOutOfProject,
                              name: `${filedArrayRootName}[${index}].jiraProject`,
                              placeholder: 'Category',
                              withLabel: false,
                              fieldData: {
                                styles: {
                                  option: (base, state) => {
                                    const styles = get(
                                      state,
                                      'data.styles',
                                      {},
                                    );

                                    return {
                                      ...base,
                                      ...styles,
                                    };
                                  },
                                },
                                selected: {},
                                items: projectsList,
                              },
                              cssRules: `
                              && {
                                font-size: 1.2rem;
                                line-height: 2.4rem;
                                letter-spacing: -0.025rem;
                                && .select-item__value-container {
                                  overflow-x: hidden;
                                }
                                & .select-item__menu-list {
                                max-height: 25rem;
                                }
                              }
                            `,
                              validationRules: {
                                isRequired: true,
                              },
                            },
                            {
                              type: FORM_UNITS_TYPES.TEXT,
                              label: 'Role',
                              isHidden: isOther,
                              name: `${filedArrayRootName}[${index}].role`,
                              fieldData: {
                                value: role,
                                placeholder: 'Role',
                              },
                              cssRules: `
                              && {
                                font-size: 1.2rem;
                                line-height: 2.4rem;
                                letter-spacing: -0.025rem;
                                color: ${
  !isBlocked ?
    colorSecondaryText :
    colorSecondaryGrayLight
};
                                ${
  !isBlocked ?
    '' :
    'pointer-events: none;'
}
                              }
                            `,
                              validationRules: {
                                isRequired: true,
                              },
                            },
                            {
                              type: FORM_UNITS_TYPES.VIEW_BOX,
                              label: 'Hours',
                              fieldData: {
                                value: isOutOfProject ? '--' : loggedHrs,
                              },
                              cssRules: `
                              justify-content: flex-end;
                              border-bottom: none;
                              padding: 1.8rem 1.8rem 0 0;
                              font-size: 1.2rem;
                              line-height: 2.1rem;
                              text-align: right;
                              color: ${
  !isBlocked ?
    colorSecondaryText :
    colorSecondaryGrayLight
};
                            `,
                            },
                            {
                              isMultiple: true,
                              cssRules: isOther || !isBlocked ?
                                `
                              display: flex;
                              flex-direction: column;
                            ` :
                                `
                              display: grid;
                              grid-template-columns: minmax(0, 120fr) minmax(0, 480fr) minmax(0, 160fr) minmax(0, 3.2rem);
                              grid-column-gap: 0.8rem;
                            `,
                              data: !isBlocked || isOther ?
                                [
                                  {
                                    isFieldArray: true,
                                    name: `${filedArrayRootName}[${index}].reviews`,
                                    label: 'Reviews',
                                    validateOnChange: false,
                                    fieldData: {
                                      onPushLine: ({ addLine, values }) => {
                                        if (!values.length) {
                                          addLine(defaultReview);
                                        }
                                        addLine(defaultReview);
                                      },
                                      defaultLine: defaultReview,
                                      hasDefaultValues:
                                        get(records, `${index}.reviews`, []).length === 0 || !get(records, `${index}.isBlocked`, true),
                                      onDeleteLine: ({
                                        values,
                                        removeLine,
                                        setFieldValue,
                                        setFieldError,
                                        index: deletedLineIndex,
                                      }) => {
                                        const recordReviewsName = `${filedArrayRootName}[${index}].reviews[${deletedLineIndex}]`;

                                        Object.keys(defaultReview).forEach((field) => {
                                          if (toast.isActive(`${recordReviewsName}.${field}`)) {
                                            toast.dismiss(`${recordReviewsName}.${field}`);
                                            setFieldError(`${recordReviewsName}.${field}`, null);
                                          }
                                        });

                                        const isNeedResetReviews =
                                            values.reduce(
                                              (acc, row, rowIndex) => rowIndex === deletedLineIndex ?
                                                acc :
                                                isEmpty(row),
                                              false,
                                            );
                                        if (isNeedResetReviews) {
                                          return setFieldValue(
                                            `${filedArrayRootName}[${index}].reviews`,
                                            [{}],
                                          );
                                        }

                                        return removeLine(deletedLineIndex);
                                      },
                                      lineGetter: ({
                                        index: innerLineIndex,
                                        lineValues: innerLineValues,
                                      }) => {
                                        const {
                                          recordId,
                                          reviewbyId,
                                          review,
                                          rating,
                                        } = innerLineValues;

                                        const showClearButton = review || rating || reviewbyId;
                                        const showDeleteReviewButton = isMoreThatOneReview && (isOutOfProject || innerLineIndex);
                                        const showDeleteRowButton = isOutOfProject && !isMoreThatOneReview;

                                        return {
                                          lineId: hash({
                                            lineId:
                                              recordId || innerLineIndex,
                                          }),
                                          line: [
                                            {
                                              type: isCPS ? FORM_UNITS_TYPES.EMPTY : FORM_UNITS_TYPES.SLIDER,
                                              name: `${filedArrayRootName}[${index}].reviews[${innerLineIndex}].rating`,
                                              label: 'Rating',
                                              fieldData: {
                                                value: innerLineIndex,
                                              },
                                              isLocked: isBlocked && !isOther,
                                              validationRules: {
                                                isRequired: true,
                                              },
                                            },
                                            {
                                              type: FORM_UNITS_TYPES.TEXT,
                                              name: `${filedArrayRootName}[${index}].reviews[${innerLineIndex}].review`,
                                              label: 'Review',
                                              isMultiline: true,
                                              fieldData: {
                                                placeholder: 'Review',
                                              },
                                              cssRules: `
                                          && {
                                            padding-top: 0.3rem;
                                            font-size: 1.2rem;
                                            line-height: 1.6rem;
                                            letter-spacing: -0.025rem;
                                            color: ${colorSecondaryText};

                                            && .text-input__input-field {
                                              padding-bottom: 0.68rem
                                            }
                                          }
                                        `,
                                              validationRules: {
                                                isRequired: true,
                                              },
                                            },
                                            {
                                              type: isCPS ?
                                                FORM_UNITS_TYPES.EMPTY :
                                                FORM_UNITS_TYPES.SELECT,
                                              name: `${filedArrayRootName}[${index}].reviews[${innerLineIndex}].reviewbyId`,
                                              placeholder: 'Reviewer',
                                              label: 'Review by',
                                              isHidden: isCPS,
                                              withLabel: false,
                                              fieldData: {
                                                styles: {
                                                  option: (base, state) => {
                                                    const styles = get(
                                                      state,
                                                      'data.styles',
                                                      {},
                                                    );

                                                    return {
                                                      ...base,
                                                      ...styles,
                                                    };
                                                  },
                                                },
                                                selected: get(
                                                  employeesMap,
                                                  reviewbyId,
                                                  {},
                                                ),
                                                itemsMap: employeesMap,
                                                items: [
                                                  !isOther && {
                                                    label: 'Team',
                                                    options: teamManagement,
                                                  },
                                                  {
                                                    label: 'All',
                                                    options: employeesList,
                                                  },
                                                ],
                                              },
                                              cssRules: `
                                                display: flex;
                                                padding-bottom: 0.3rem;
                                                flex-direction: column;
                                                justify-content: flex-end;
                                                font-size: 1.2rem;
                                                & .select-item {
                                                  height: 4.4rem;
                                                }
                                                && .select-item__value-container {
                                                  overflow-x: hidden;
                                                }
                                                & .select-item__menu {
                                                  width: calc(120% + 0.8rem + 2px);
                                                }
                                                & .select-item__group {
                                                  padding: 0;
                                                }
                                                & .select-item__group-heading {
                                                  position: sticky;
                                                  top: 0;
                                                  margin: 0;
                                                  padding: 0.8rem 0;
                                                  border-bottom: 1px solid rgba(85, 86, 90, 0.2);
                                                  font-size: 1.2rem;
                                                  line-height: 1.6rem;
                                                  font-weight: 500;
                                                  text-align: center;
                                                  letter-spacing: 1.5px;
                                                  text-transform: uppercase;
                                                  background-color: ${colorWhite};
                                                  z-index: 1;
                                          }
                                        `,
                                              validationRules: {
                                                isRequired: true,
                                              },
                                            },

                                            {
                                              type:
                                                (!isBlocked && !isCPS) || isOther ?
                                                  FORM_UNITS_TYPES.DROPDOWN :
                                                  FORM_UNITS_TYPES.EMPTY,
                                              controls: [
                                                {
                                                  type: 'action',
                                                  data: 'Add new',
                                                  innerActionName: `${filedArrayRootName}[${index}].reviews_addLine`,
                                                  withBody: true,
                                                  cssRules: `
                                              && {
                                                width: 100%;
                                                padding: 0.7rem 2.4rem;
                                                font-size: 1.3rem;
                                                line-height: 1.6rem;
                                                text-align: center;
                                                color: ${colorWhite};
                                                background-color: ${colorPrimary};
                                              }
                                            `,
                                                },
                                                showClearButton && {
                                                  type: 'action',
                                                  data: 'Clear',
                                                  withBody: true,
                                                  innerTargetName:
                                                    isLastStoredReview ?
                                                      `${filedArrayRootName}[${index}]` :
                                                      `${filedArrayRootName}[${index}].reviews[${innerLineIndex}]`,
                                                  innerGetTargetValue: (rows) => {
                                                    if (isLastStoredReview) {
                                                      const currentRowData =
                                                        get(
                                                          rows,
                                                          `${filedArrayRootName}[${index}]`,
                                                          {},
                                                        );

                                                      return {
                                                        ...currentRowData,
                                                        role: role || defaultRole,
                                                        reviews: [defaultReview],
                                                      };
                                                    }
                                                    return {};
                                                  },
                                                  cssRules: `
                                              && {
                                                width: 100%;
                                                padding: 0.7rem 2.4rem;
                                                font-size: 1.3rem;
                                                line-height: 1.6rem;
                                                text-align: center;
                                                border-color: ${colorWhite};
                                                color: ${colorWhite};
                                                background-color: transparent;
                                                &:hover {
                                                  border-color: transparent;
                                                  color: ${colorPrimary};
                                                  background: ${colorWhite};
                                                }
                                              }
                                            `,
                                                },
                                                showDeleteReviewButton && {
                                                  type: 'action',
                                                  data: 'Delete',
                                                  withBody: true,
                                                  innerActionName: `${filedArrayRootName}[${index}].reviews_deleteLine`,
                                                  cssRules: `
                                              && {
                                                width: 100%;
                                                padding: 0.7rem 2.4rem;
                                                font-size: 1.3rem;
                                                line-height: 1.6rem;
                                                text-align: center;
                                                border-color: ${colorWhite};
                                                color: ${colorWhite};
                                                background-color: transparent;
                                                &:hover {
                                                  border-color: transparent;
                                                  color: ${colorPrimary};
                                                  background: ${colorWhite};
                                                }
                                              }
                                            `,
                                                  actionArguments: {
                                                    index: innerLineIndex,
                                                  },
                                                },

                                                showDeleteRowButton && {
                                                  type: 'action',
                                                  data: 'Delete',
                                                  withBody: true,
                                                  innerActionName: `${filedArrayRootName}_deleteLine`,
                                                  cssRules: `
                                              && {
                                                width: 100%;
                                                padding: 0.7rem 2.4rem;
                                                font-size: 1.3rem;
                                                line-height: 1.6rem;
                                                text-align: center;
                                                border-color: ${colorWhite};
                                                color: ${colorWhite};
                                                background-color: transparent;
                                                &:hover {
                                                  border-color: transparent;
                                                  color: ${colorPrimary};
                                                  background: ${colorWhite};
                                                }
                                              }
                                            `,
                                                  actionArguments: {
                                                    index,
                                                  },
                                                },
                                              ],
                                              cssRules: `
                                          height: 3.2rem;
                                          & path {
                                            fill: ${colorGrayLight08};
                                          }
                                          &.dropdown--opened {
                                            & path {
                                              fill: ${colorWhite};
                                            }
                                          }
                                        `,
                                              expandSectionCssRules: `
                                          top: calc(100 - 0.2rem) !important;
                                          left: unset !important;
                                          right: 0!important;
                                          width: calc(600% + 0.8rem + 2px);
                                          background-color: #414042;
                                        `,
                                            },
                                          ],
                                          stylesTemplate: {
                                            cssRules: `
                                        grid-template-columns: minmax(0, 120fr) minmax(0, 480fr) minmax(0, 160fr) minmax(0, 3.2rem);
                                        grid-column-gap: 0.8rem;
                                        grid-row-gap: 0.4rem;
                                        margin-top: 0;
                                        padding: 0;
                                      `,
                                          },
                                        };
                                      },
                                    },
                                  },
                                ] :
                                [
                                  {
                                    type: FORM_UNITS_TYPES.SLIDER,
                                    isLocked: isBlocked,
                                  },
                                  {
                                    type: FORM_UNITS_TYPES.TEXT,
                                    name: `${filedArrayRootName}[${index}].blockReason`,
                                    isMultiline: true,
                                    fieldData: {
                                      placeholder: 'Review not required',
                                    },
                                    cssRules: `
                                  && {
                                    padding-top: 0.3rem;
                                    font-size: 1.2rem;
                                    line-height: 1.6rem;
                                    letter-spacing: -0.025rem;
                                    color: ${colorSecondaryGrayLight};
                                    && .text-input__input-field {
                                      padding-bottom: 0.5rem;
                                    }
                                  }
                                `,
                                    validationRules: {
                                      isRequired: true,
                                    },
                                  },
                                  {
                                    type: FORM_UNITS_TYPES.VIEW_BOX,
                                    fieldData: {
                                      value: 'RM',
                                    },
                                    cssRules: `
                                  border-bottom: none;
                                  padding-top: 1.8rem;
                                  font-size: 1.2rem;
                                  line-height: 2.1rem;
                                  color: ${colorSecondaryGrayLight};
                                `,
                                  },
                                ],
                            },
                          ],
                          stylesTemplate: {
                            cssRules: `
                            grid-template-columns: minmax(0, 4.8rem) ${
  isOther ?
    'minmax(0, 218fr)' :
    'minmax(0, 170fr) minmax(0, 40fr)'
} minmax(0, 170fr) minmax(0, 60fr) minmax(0, 815fr);
                            grid-column-gap: 0.8rem;
                            grid-row-gap: 0.8rem;
                            margin-top: 0;
                            padding: 0;
                            &:last-child {
                              margin-bottom: 6rem;
                            }
                          `,
                          },
                        };
                      },
                      headersConfig: {
                        stylesTemplate: {
                          cssRules: `
                          grid-template-columns: minmax(0, 4.8rem) ${
  isOther ?
    'minmax(0, 218fr)' :
    'minmax(0, 170fr) minmax(0, 40fr)'
} minmax(0, 170fr) minmax(0, 60fr) minmax(0, 120fr) minmax(0, 480fr) minmax(0, 160fr) minmax(0, 3.2rem);
                          grid-column-gap: 0.8rem;
                          grid-row-gap: 0;
                          margin-top: 0.4rem;
                          padding: 0;
                        `,
                        },
                        data: [
                          {
                            type: 'actionButton',
                            data: '',
                            targetName: jiraProject,
                            getTargetValue: (values) => get(values, jiraProject, []).map((row) => {
                              const {
                                isBlocked,
                                devstaffId,
                                loggedHrs,
                              } = row;
                              const isAdditionalReviews =
                                  !toNumber(loggedHrs);
                              const storedReviews = get(
                                storedData,
                                devstaffId,
                                [],
                              );

                              if (isAdditionalReviews) {
                                return row;
                              }
                              if (!isBlocked) {
                                storedData[devstaffId] = row.reviews;
                              }
                              return {
                                ...row,
                                isBlocked: !hasRequiredReviews,
                                reviews: hasRequiredReviews ?
                                  [] :
                                  storedReviews,
                              };
                            }),
                            onFormChange: ({ values }) => {
                              const rows = get(values, jiraProject, []);
                              const isAllOutOfProject = get(
                                rows,
                                '[0].isOutOfProject',
                                false,
                              );
                              const hasStoredRequiredReviews = rows.some(({ isBlocked, loggedHrs }) => !isBlocked && !!toNumber(loggedHrs));

                              return {
                                imgSrc:
                                  hasStoredRequiredReviews ||
                                  isAllOutOfProject ?
                                    VisibilityOnIcon :
                                    VisibilityOffIcon,
                                cssRules: `
                              && {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 0.4rem;
                                border-bottom: 1px solid ${colorSecondaryGrayLight};
                                ${
  isAllOutOfProject ?
    'pointer-events: none;' :
    ''
}
                              }
                              && .action-button__icon {
                                margin-left: 0;
                                ${isAllOutOfProject ? 'opacity: 0.3;' : ''}
                              }
                            `,
                              };
                            },
                          },
                          {
                            type: 'orderButton',
                            data: 'Resource',
                            actionName: 'changeOrder',
                            cssRules: `
                          && {
                            ${formHeaderCss}
                            text-align: left;
                            padding: 0.6rem 0 0 0;
                            font-size: 1.2rem;
                            color: ${colorSecondaryText};
                            border-bottom: 1px solid ${colorSecondaryGrayLight};
                          }
                          && {
                            .order-button__icon {
                              display: flex;
                              align-items: center;
                            }
                          }
                        `,
                          },
                          {
                            isHidden: isOther,
                            value: {
                              title: 'PL',
                              cssRules: `
                            padding-bottom: 0.8rem;
                            font-size: 1.2rem;
                          `,
                            },
                          },
                          {
                            value: {
                              title: isOther ? 'Category' : 'Role',
                              cssRules: `
                            padding-bottom: 0.8rem;
                            font-size: 1.2rem;
                          `,
                            },
                          },
                          {
                            value: {
                              title: 'Hours',
                              cssRules: `
                            padding: 0 1.8rem 0.8rem 0;
                            font-size: 1.2rem;
                            text-align: right;
                          `,
                            },
                          },
                          {
                            value: {
                              title: 'Rating',
                              cssRules: `
                            padding-bottom: 0.8rem;
                            font-size: 1.2rem;
                          `,
                            },
                          },
                          {
                            value: {
                              title: 'Review',
                              cssRules: `
                            padding-bottom: 0.8rem;
                            font-size: 1.2rem;
                          `,
                            },
                          },
                          {
                            value: {
                              title: 'Review by',
                              cssRules: `
                            padding-bottom: 0.8rem;
                            font-size: 1.2rem;
                          `,
                            },
                          },
                          {},
                        ],
                      },
                      footerConfig: isOther && {
                        stylesTemplate: {
                          cssRules: `
                          grid-template-columns: minmax(0, 220fr) minmax(0, 853fr);
                          grid-column-gap: 0.8rem;
                          margin: 0 0 2.4rem;
                          grid-auto-rows: minmax(3.2rem, auto);
                          padding: 0;
                        `,
                        },
                        data: [
                          {
                            type: 'actionButton',
                            data: 'Add',
                            actionName: `${filedArrayRootName}_addLine`,
                            withBody: true,
                            cssRules: isCPS ?
                              `&& {
                                display: none;
                              }` :
                              `
                          && {
                            box-sizing: border-box;
                            padding: 0.7rem 1.2rem;
                            color: ${colorSecondaryGrayDark};
                            line-height: 1.6rem;
                            letter-spacing: 0.0125rem;
                            text-align: center;
                            font-size: 1.4rem;
                            font-weight: 500;
                            border: 1px solid ${colorSecondaryGrayDark};
                          }
                          &:hover {
                            border: 1px solid #d61f26;
                            color: #fff;
                          }
                        `,
                          },
                        ],
                      },
                    };
                  },
                  validationRules: {
                    schemaGetter: () => array()
                      .of(object().shape({
                        jiraProject: number()
                          .nullable()
                          .test(
                            'isOtherProject',
                            'Required filed',
                            (project) => isOther ? !isUndefined(project) : true,
                          ),
                        devstaffId: number().required('Required filed'),
                        role: string()
                          .nullable()
                          .when(
                            ['reviews', 'devstaffId'],
                            (rev, devstaffId, schema) => {
                              const initialRole = get(
                                projectMap,
                                `${devstaffId}.role`,
                              );
                              const defaultRole = get(
                                projectMap,
                                `${devstaffId}.defaultRole`,
                              );

                              return schema.test(
                                'hasReviews',
                                'At least one review record should be filled in to change the resource role',
                                (currentRole) => !isOther &&
                                    currentRole !== initialRole &&
                                    currentRole !== defaultRole ?
                                  !!rev.length :
                                  true,
                              );
                            },
                          ),
                        reviews: array().of(object().shape(isOther ? otherReviewValidator : categoryReviewValidator)),
                      })),
                  },
                },
              ],
              stylesTemplate: {
                cssRules: `
                grid-auto-rows: auto;
                grid-row-gap: 0;
                margin-top: 0;
                padding: 0;
              `,
              },
            },
          ],
        };
      }),
    ],
  };
};
