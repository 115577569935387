import { permissionsToProjectLeadAssignmentsTab } from 'core/auth/guaranteedAccessRoles';
import { get } from 'lodash';
import moment from 'moment';
import { getHasPermissions } from 'utils/auth';
import { formatDateMMDDYYYY } from 'utils/helpers/date';

import { getOnboardingHistory } from './config';
import detailsTabBodyGetter from './details-tab';
import projectLeadAssignmentsTabGetter from './project-lead-assignments-tab';
import ptoRequestsTabBodyGetter from './pto-requests-tab';
import workBookTabBodyGetter from './work-book-tab';

export const employeeDetailsModelGetter = (
  userGroup,
  userData,
  employeeDetails = {},
  projectsForPTO,
  holidays,
  devcenters,
  devCentersBySystemRole,
  formSubmitted
) => {
  const {
    fullname,
    isNew,
    isIntern,
    dateHired,
    plAssignments,
    onboardingHistory,
    workBookLastAction,
    internshipStartDate,
    ptoRequestLastAction,
    workBookRecords = [],
    devstaffId,
  } = employeeDetails;

  const hasPermissionsPlAssignment = getHasPermissions(userGroup, permissionsToProjectLeadAssignmentsTab);
  const devcentersByDate = workBookRecords.reduce((acc, record) => {
    if (!record) {
      return acc;
    }
    const { approvedCeo, devcenter, validFrom: date } = record;

    if (!approvedCeo) {
      return acc;
    }
    if (!acc.length) {
      return [{
        date,
        devcenter,
      }];
    }
    const lastStoredDevcenter = get(acc, `[${acc.length - 1}].devcenter`);
    if (lastStoredDevcenter !== devcenter) {
      return [...acc, {
        date,
        devcenter,
      }];
    }
    return acc;
  }, []);
  const getDevcenterByDate = (currentDate) => {
    const devcenterData = devcentersByDate.find(({ date }) => {
      const momentDate = moment(date, 'YYYY-MM-DD');
      return moment(currentDate, 'YYYY-MM-DD').isSameOrAfter(momentDate);
    }) || devcentersByDate[0];
    return get(devcenterData, 'devcenter', 1);
  };
  const currentPlAssignments = Array.isArray(plAssignments) ? plAssignments.filter(({
    dateThrough,
  }) => !dateThrough) : [];
  const history = getOnboardingHistory(onboardingHistory);
  const generateWorkbookTabBody = () => {
    if (!(dateHired || internshipStartDate)) {
      return [];
    }
    return workBookTabBodyGetter({
      userGroup,
      userData,
      history,
      employeeDetails,
      devcenters,
      devCentersBySystemRole,
    });
  };

  return {
    rules: {
      css: {},
    },
    additionalDataGetter: ({
      isDismissed,
      isOnLeave,
    }) => ({
      reviewLinkCssRules: `
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        font-size: 1.4rem;
        font-weight: 500;
        color: #58595b;
      `,
      employeeStatus: (() => {
        if (isDismissed) {
          return 'Dismissed';
        }

        if (isOnLeave) {
          return 'On Leave';
        }

        if (isNew) {
          return 'New';
        }

        if (isIntern) {
          return 'Intern';
        }

        return 'Employed';
      })(),
    }),
    dataTemplate: [
      {
        tabHeader: {
          title: 'Details',
          labels: [{
            valueGetter: () => ({
              value: fullname,
              isEmpty: !fullname,
            }),
          },
          {
            valueGetter: () => {
              if (dateHired) {
                return {
                  value: `hired: ${formatDateMMDDYYYY(dateHired)}`,
                };
              }

              if (internshipStartDate) {
                return {
                  value: `Internship Start Date: ${formatDateMMDDYYYY(internshipStartDate)}`,
                };
              }
              return {
                value: '',
                isEmpty: true,
              };
            },
          },
          ],
        },

        stylesTemplate: {
          wrapperCssRules: `
            display: grid;
            grid-template-columns: repeat(4, minmax(27.6rem, 1fr));
            grid-template-rows: none;
            grid-auto-rows: 100%;
            grid-template-areas: "generalInfo generalInfo careerBreakInfo dismissalInfo";
            padding: 0 5.6rem;
            ${devstaffId && 'padding: 0;'}
            `,
        },

        tabBody: detailsTabBodyGetter({
          userGroup,
          history,
          employeeDetails,
        }),
      },
      {
        tabHeader: {
          title: 'Work Book',
          isLocked: (!devstaffId && !(dateHired || internshipStartDate)),
          labels: [{
            valueGetter: () => {
              const updateDate = get(workBookLastAction, 'actionDate');
              return {
                value: updateDate ? `Last Updated ${moment(updateDate).format('L')}` : 'no updates',
                isEmpty: !updateDate,
              };
            },
          },
          {
            valueGetter: () => {
              const actionType = get(workBookLastAction, 'actionType');

              return {
                value: actionType || '',
                isEmpty: false,
                isActive: true,
              };
            },
          },
          ],
        },

        stylesTemplate: {
          wrapperCssRules: 'padding-bottom: 10rem;',
        },

        tabBody: generateWorkbookTabBody(),
      },
      {
        tabHeader: {
          title: 'PTO requests',
          isLocked: !devstaffId ? true : (isNew || isIntern),
          labels: [{
            valueGetter: () => {
              const updateDate = get(ptoRequestLastAction, 'actionDate');

              return {
                value: updateDate ? `Last Updated ${moment(updateDate).format('L')}` : 'no updates',
                isEmpty: !updateDate,
              };
            },
          },
          {
            valueGetter: () => {
              const actionType = get(ptoRequestLastAction, 'actionType');

              return {
                value: actionType || '',
                isEmpty: false,
                isActive: true,
              };
            },
          },
          ],
        },

        stylesTemplate: {
          wrapperCssRules: 'padding-bottom: 10rem;',
        },

        tabBody: ptoRequestsTabBodyGetter({
          holidays,
          userGroup,
          userData,
          employeeDetails,
          projectsForPTO,
          getDevcenterByDate,
          formSubmitted,
        }),
      },
      {
        tabHeader: {
          title: 'Project Lead Assignments',
          isLocked: isNew || isIntern || !hasPermissionsPlAssignment || !devstaffId,
          labels: [{
            valueGetter: () => {
              const plAssignmentsLength = get(plAssignments, 'length', 0);
              const value = plAssignmentsLength ?
                `${plAssignmentsLength} project${plAssignmentsLength > 1 ? 's' : ''}` :
                'No current assignments';

              return {
                value,
                isEmpty: plAssignmentsLength === 0,
              };
            },
          },
          {
            valueGetter: () => {
              const plAssignmentsLength = get(currentPlAssignments, 'length', 0);
              const value = plAssignmentsLength ?
                `${plAssignmentsLength} PL assignment${plAssignmentsLength > 1 ? 's' : ''}` :
                '';

              return {
                value,
                isEmpty: plAssignmentsLength === 0,
              };
            },
          },
          ],
        },

        tabBody: projectLeadAssignmentsTabGetter({
          userGroup,
          employeeDetails,
        }),
      },
    ],
  };
};
